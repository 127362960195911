// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-about-section-tsx": () => import("./../../../src/pages/home/AboutSection.tsx" /* webpackChunkName: "component---src-pages-home-about-section-tsx" */),
  "component---src-pages-home-contacts-section-tsx": () => import("./../../../src/pages/home/ContactsSection.tsx" /* webpackChunkName: "component---src-pages-home-contacts-section-tsx" */),
  "component---src-pages-home-header-section-tsx": () => import("./../../../src/pages/home/HeaderSection.tsx" /* webpackChunkName: "component---src-pages-home-header-section-tsx" */),
  "component---src-pages-home-home-page-tsx": () => import("./../../../src/pages/home/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-home-page-tsx" */),
  "component---src-pages-home-projects-section-tsx": () => import("./../../../src/pages/home/ProjectsSection.tsx" /* webpackChunkName: "component---src-pages-home-projects-section-tsx" */),
  "component---src-pages-home-services-section-tsx": () => import("./../../../src/pages/home/ServicesSection.tsx" /* webpackChunkName: "component---src-pages-home-services-section-tsx" */),
  "component---src-pages-home-tech-section-tsx": () => import("./../../../src/pages/home/TechSection.tsx" /* webpackChunkName: "component---src-pages-home-tech-section-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

